<template>
  <body class="print-wrapper">
    <div class="credit-buttons" style="margin:0 0 20px;">
      <button class="button button--black" @click="print">
        <span class="button__text">پرینت کردن اطلاعات</span>
      </button>
    </div>
    <div class="print">
      <img src="@img/millenium.jpg" class="print__logo" />
      <h1 class="print__store">میلنیوم</h1>
      <h2 class="print__price">10,000,000</h2>
      <div class="print__table">
        <div class="print__col">
          <h2 class="print__title">تاریخ اعتبار</h2>
          <h3 class="print__value">1400/06/09</h3>
        </div>
        <i class="print__vr"></i>
        <div class="print__col">
          <h2 class="print__title">سریال بن</h2>
          <h3 class="print__value">WXYK987</h3>
        </div>
      </div>
      <div class="print__col col--desc">
        <h2 class="print__title">توضیحات</h2>
        <h3 class="print__value">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با قرار
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با قرار
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با قرار
          گیرد.
        </h3>
      </div>
      <i class="table__hr"></i>
      <div class="print__table table--corp">
        <div class="print__row row--corp">
          <img src="@img/customer-test.jpg" class="print__corp-img" />
          <div class="print__corp">
            <h2 class="print__title">شریک تجاری</h2>
            <h3 class="print__corp-title">یلدا مسؤول فروش شرکت نفت</h3>
          </div>
        </div>
        <div class="print__row row--barcode">
          <img src="@img/Barcode.png" />
        </div>
      </div>
    </div>
    <!-- <table class="print-table">
      <thead>
        <tr>
          <th style="width: 30%">نام شرکت</th>
          <th style="width: 70%">میلنیوم</th>
        </tr>
        <tr rowspan="2">
          <th>لوگوی شرکت</th>
          <th class="img-cell"><img src="@img/millenium.jpg" /></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>شرکت همکار</td>
          <td>دیجیکالا</td>
        </tr>
        <tr rowspan="2">
          <td>لوگوی شرکت همکار</td>
          <td class="img-cell"><img src="@img/millenium.jpg" /></td>
        </tr>
        <tr>
          <td>مبلغ بن</td>
          <td>100,000</td>
        </tr>
        <tr>
          <td>تاریخ اعتبار</td>
          <td>1400/02/09</td>
        </tr>
        <tr>
          <td>توضیحات</td>
          <td>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با قرار
            گیرد.
          </td>
        </tr>
        <tr>
          <td>سریال بن</td>
          <td>WXYK987</td>
        </tr>
      </tbody>
    </table> -->
  </body>
</template>

<script>
export default {
  setup() {
    const print = () => {
      window.print()
    }

    return { print }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.print-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  font-size: 16px;
  font-family: 'IranSans';
}
.print {
  width: 21cm;
  height: 14.85cm;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  padding-top: 10px;
  margin-bottom: 10px;
}
.print__logo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.print__store {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.print__price {
  font-size: 60px;
  position: relative;
  margin: 10px 0 50px;
}
.print__price::after {
  content: 'ریال';
  font-size: 20px;
  position: relative;
  margin-right: 10px;
  bottom: 10px;
}
.print__price::before {
  content: 'بن خرید رایگان';
  font-size: 18px;
  width: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: -15px;
}
.print__table {
  width: 96%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
}
.print__col {
  display: flex;
  flex-direction: column;
  width: 49.9%;
  align-items: center;
  padding: 10px 0;
}
.print__title {
  font-size: 16px;
  text-align: right;
  margin-bottom: 10px;
}
.print__vr {
  height: 40px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}
.col--desc {
  width: 96%;
  margin: 20px 0;
}
.col--desc .print__value {
  text-align: justify;
  max-width: 90%;
}
.table--corp {
  margin-top: 20px;
  background-color: #fff;
}
.print__row {
  display: flex;
  width: 50%;
  align-items: center;
  padding: 0 10px;
  height: 60px;
}
.print__hr {
  height: 1px;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.3);
}
.print__corp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}
.print__corp-img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.print__corp-title {
  font-size: 14px;
}
.row--corp .print__value {
  text-align: center;
  max-width: 50%;
}
.row--barcode {
  justify-content: flex-end;
  img {
    height: 60px;
  }
}

// table
table {
  width: 21cm;
  // height: 14.85cm;
  border-collapse: collapse;
  border-spacing: 0;
  overflow: hidden;
}
td,
th {
  border: 1px solid #777;
  padding: 10px;
}
th,
tr,
td {
  text-align: center;
  vertical-align: middle;
}

.img-cell img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
</style>
