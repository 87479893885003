<template>
  <div class="stat-wrapper">
    <section class="load-resolved" v-if="stat">
      <div class="chartTitle">
        <h3 class="chartTitle__text">مبلغ بن خرید ایجاد شده</h3>
        <h4 class="chartTitle__number chartTitle--rial">
          {{ chart && chart['allPrice'].toLocaleString() }}
        </h4>
      </div>
      <div class="cmp-items-wrapper">
        <div class="chart">
          <doughnut
            :dataset="[
              chart['priceActive'] == 0 ? 0.001 : chart['priceActive'],
              chart['priceUsed'] == 0 ? 0.001 : chart['priceUsed'],
              chart['priceExpired'] == 0 ? 0.001 : chart['priceExpired']
            ]"
            :colors="['#c69d4e', '#777', '#333']"
            :size="150"
            :thickness="15"
            style="position:relative; bottom: 5px;"
          />
          <div class="chart__legend">
            <div class="chart__row">
              <span class="chart__value value--gold"
                >{{ chart['activePercent'] }}%</span
              >
              <h5 class="chart__title">بن های فعال</h5>
            </div>
            <div class="chart__row">
              <span class="chart__value value--silver"
                >{{ chart['usedPercent'] }}%</span
              >
              <h5 class="chart__title">بن های مصرف شده</h5>
            </div>
            <div class="chart__row">
              <span class="chart__value value--dark"
                >{{ chart['expiredPercent'] }}%</span
              >
              <h5 class="chart__title">بن های باطل شده</h5>
            </div>
          </div>
        </div>
        <div class="chart chart--hist">
          <div class="hist-wrapper">
            <div class="chart-row" v-for="bar in bars" :key="bar.id">
              <div class="chart-bar-row">
                <div class="chart-bar">
                  <i
                    :style="{
                      backgroundColor: bar['color'],
                      width: bar['width'] === '0%' ? '3px' : bar['width']
                    }"
                  />
                </div>
              </div>
              <div class="chart-row-stat">
                <h6>{{ bar['count'] }}</h6>
                <h5>{{ bar['title'] }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-wrapper">
        <div>
          <h5>تعداد همه بن ها</h5>
          <h6>{{ chart['allCount'] }}</h6>
        </div>
        <hr />
        <div>
          <h5>تعداد بن های فعال</h5>
          <h6>{{ chart['active'] }}</h6>
        </div>
        <hr />
        <div>
          <h5>تعداد بن های مصرف شده</h5>
          <h6>{{ chart['used'] }}</h6>
        </div>
        <hr />
        <div>
          <h5>تعداد بن های باطل شده</h5>
          <h6>{{ chart['expired'] }}</h6>
        </div>
      </div>
    </section>
    <section class="load-pending" v-else>
      <img src="@img/vipon-loading.svg" />
    </section>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import Doughnut from '@common/Doughnut'

export default {
  name: 'GiftStat',
  components: {
    Doughnut
  },
  setup() {
    const store = useStore()
    store.dispatch('fetchStat', { part: 'bon' })
    const stat = computed(() => store.state.stat)

    /******************
     * Doughnut Maker *
     ******************/
    const chart = ref(null)
    const bars = reactive([])

    watch(stat, (stat) => {
      chart.value = {}
      // Init doughnut
      const allPrice =
        stat['price_active'] + stat['price_used'] + stat['price_expired']
      const activePercent =
        allPrice === 0 ? 0 : Math.round((stat['price_active'] / allPrice) * 100)
      const usedPercent =
        allPrice === 0 ? 0 : Math.round((stat['price_used'] / allPrice) * 100)
      const expiredPercent =
        allPrice === 0
          ? 0
          : Math.round((stat['price_expired'] / allPrice) * 100)

      chart.value['allCount'] = stat['active'] + stat['used'] + stat['expired']
      chart.value['allPrice'] = allPrice
      chart.value['priceActive'] = stat['price_active']
      chart.value['priceUsed'] = stat['price_used']
      chart.value['priceExpired'] = stat['price_expired']
      chart.value['active'] = stat['active']
      chart.value['used'] = stat['used']
      chart.value['expired'] = stat['expired']
      chart.value['activePercent'] = activePercent
      chart.value['usedPercent'] = usedPercent
      chart.value['expiredPercent'] = expiredPercent

      // Init Bars
      bars[2] = {
        id: 1,
        count: stat['price_expired'].toLocaleString(),
        width: expiredPercent + '%',
        color: '#333',
        title: 'مبلغ بن های باطل شده'
      }
      bars[1] = {
        id: 2,
        count: stat['price_used'].toLocaleString(),
        width: usedPercent + '%',
        color: '#777',
        title: 'مبلغ بن های مصرف شده'
      }
      bars[0] = {
        id: 3,
        count: stat['price_active'].toLocaleString(),
        width: activePercent + '%',
        color: '#c69d4e',
        title: 'مبلغ بن های فعال'
      }
    })

    onBeforeUnmount(() => store.dispatch('clearStat'))
    return { stat, chart, bars }
  }
}
</script>
