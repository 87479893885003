<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar :msg="title" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <gift-card
          v-for="card in giftItems"
          :code="card['code']"
          :amount="card['amount']"
          :remain="card['remain']"
          :key="card['id']"
          :is-dark="isDark"
          @click="copyCode(card['code'])"
        />
        <loader v-show="pageStatus == 'loading' && !firstLoad" />
        <observer @intersect="intersected" />
      </div>
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from '@vue/runtime-core'
import { useStore } from 'vuex'
import GiftCard from './subComponents/GiftCard'

export default {
  name: 'GiftStatus',
  components: {
    GiftCard
  },
  props: ['id', 'status'],
  setup(props) {
    const store = useStore()
    const isDark = computed(() => props.status !== '1')
    const title = computed(() => {
      switch (props.status) {
        case '1':
          return 'بن فعال'

        case '2':
          return 'بن مصرف شده'

        case '3':
          return 'بن باطل شده'

        default:
          return null
      }
    })
    const pageStatus = computed(() => store.state.gift.pageStatus)

    // API call
    const giftItems = computed(() => store.state.gift.backupItems)
    store.dispatch('gift/fetchItems', {
      part: 'bonItem',
      backup: true,
      query: { bon: props.id, status: props.status }
    })

    // Intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('gift/fetchItems', {
        part: 'bonItem',
        backup: true,
        query: { bon: props.id, status: props.status }
      })
    }

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Copy gift code to clipboard
    const copyCode = async (code) => {
      try {
        await navigator.clipboard.writeText(code.toUpperCase())
        store.dispatch('addToast', {
          type: 'success',
          message: 'کد بن خرید کپی شد.'
        })
      } catch (ex) {
        console.log(ex)
      }
    }

    // Clean the room
    onBeforeUnmount(() => store.dispatch('gift/clearBackupItems'))

    return {
      isDark,
      title,
      pageStatus,
      giftItems,
      intersected,
      firstLoad,
      copyCode
    }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  background: #eee;
  padding-bottom: 75px;
}
.cmp-items-wrapper {
  width: 96%;
}
.cmp-wrapper--dark {
  background-color: #080808;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
