<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar msg="تراکنش‌های بن خرید" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <gift-transaction
          v-for="trn in transactions"
          :key="trn['id']"
          :price="trn['price']"
          :receiver-name="trn['cashier']"
          :date="localizeDate(trn['created_at'])"
          :hour="getHour(trn['created_at'])"
          :dark="isDark"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { useStore } from 'vuex'
import GiftTransaction from './subComponents/GiftTransaction'
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'GiftTransactions',
  props: ['code', 'id'],
  components: {
    GiftTransaction
  },
  setup(props) {
    const store = useStore()
    const transactions = computed(() => store.state.gift.backupItems)
    const pageStatus = computed(() => store.state.gift.pageStatus)
    const isDark = computed(() => store.state.gift.isDark)

    const getTrns = () => {
      store.dispatch('gift/fetchItems', {
        part: 'transaction',
        backup: true,
        query: { type: 3, relation_id: props.id }
      })
    }
    getTrns()

    // Intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      getTrns()
    }

    // Handle two loadings
    const firstLoad = ref(true)
    watch(pageStatus, (curr) => {
      if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
    })

    // Store necessary items
    onBeforeUnmount(() => {
      store.dispatch('gift/clearBackupItems')
    })

    // ------- //
    const { localizeDate, getHour } = dateConvertor()
    return {
      isDark,
      transactions,
      pageStatus,
      intersected,
      firstLoad,
      localizeDate,
      getHour
    }
  }
}
</script>

<style lang="scss" scoped>
// change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
