<template>
  <div class="single-offer-wrapper">
    <div :class="['gift', isDark && 'gift--dark', giftInner && 'gift--inner']">
      <div class="gift__right">
        <h2 class="gift__count" v-text="count" />
        <span class="gift__gift">gift card</span>
      </div>
      <i class="gift__sep" />
      <div class="gift__left">
        <img class="gift__img" :src="image" v-show="flip" @load="flip = true" />
        <img class="gift__img" src="@img/preload-logo.svg" v-if="!flip" />
        <h6 class="gift__title">{{ organ }}</h6>
      </div>
      <i class="gift__circle circle--top" />
      <i class="gift__circle circle--bottom" />
      <!-- <i class="gift__circle circle--left" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'GiftSingle',
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    giftInner: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    image: {
      // #TODO
      type: String
    },
    organ: {
      type: String
    }
  },
  data() {
    return {
      flip: false
    }
  }
}
</script>

<style scoped lang="scss">
$light-border: #ddd;
$dark-border: #383838;
$gift-svg-width: 37px;
$dark-bg: #131313;
$parent-bg-color: #111;

.single-offer-wrapper {
  padding: 0;
  margin: 0 0 10px;
  width: 94%;
  height: 136px;
  cursor: pointer;
  position: relative;
}
.gift {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 0;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: calc(32.33% - 1px) 1px calc(13.25% - 30px) 1fr;
  align-items: center;
}
.gift__left {
  grid-column: 4/5;
  width: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // border-radius: 15px 0 0 15px;
  // padding-left: 11.75%;
}
.gift__right {
  // padding-right: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gift__gift {
  margin: 1px 0 0;
  padding: 0;
  text-align: center;
  color: #c69d4e;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.gift__img {
  width: 60px;
  max-height: 60px;
  border-radius: 5px;
  position: relative;
  bottom: 2px;
}
.gift__title {
  margin-right: 10px;
  padding: 0 8px;
  text-align: right;
  color: #777;
  font-size: 16px;
}
.gift__sep {
  height: 80%;
  border-right: 1.6px dashed #ccc;
}
.gift__count {
  position: relative;
  top: 5px;
  font-size: 40px;
  color: #000;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
.gift__circles-col {
  z-index: 2;
  position: absolute;
  left: -5.5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
}
.gift__side-circle {
  width: 12px;
  height: 12px;
  margin: 6px 0;
  border-radius: 100%;
  background-color: #eee;
}
$circle-radius: 24px;
.gift__circle {
  position: absolute;
  width: $circle-radius;
  height: $circle-radius;
  border-radius: 100%;
  background-color: #eee;
  right: calc(32.33% - 13px);
}
.circle--top {
  top: -14px;
}
.circle--bottom {
  bottom: -14px;
}
.circle--left {
  left: -13px;
  right: auto;
}

.gift--dark {
  background-color: $dark-bg;
}
.gift--dark .gift__count {
  color: #777;
}
.gift--dark .gift__img {
  filter: grayscale(1) invert(1);
  opacity: 0.3;
}
.gift--dark .gift__gift {
  color: #777;
}
.gift--dark .gift__sep {
  border-right-color: #2d2d2d;
}
.gift--inner.gift--dark {
  background-color: #202020;
}
.gift--inner.gift--dark .gift__circle {
  background-color: #111;
}
.gift--inner.gift--dark .gift__side-circle {
  background-color: #111;
}
</style>
