<template>
  <div class="cmp-wrapper" :class="isDark && 'dark'">
    <navbar msg="بن های خرید" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div :class="['gift-top', isDark && 'gift-top--dark']" v-if="gift">
        <img
          class="gift-top__img"
          :src="gift['image']"
          v-show="flip"
          @load="flip = true"
        />
        <img class="gift-top__img" src="@img/preload-logo.svg" v-if="!flip" />
        <h6 class="gift-top__title">{{ gift?.organization }}</h6>
      </div>
      <div :class="['table', isDark && 'table--dark']">
        <template v-for="(price, idx) in gift['prices']" :key="idx">
          <div class="table__row">
            <h6 class="table__cell cell--title">
              {{ price['count'] }}
            </h6>
            <h6 class="table__cell cell--amount ">
              {{ price['price'].toLocaleString() }}
            </h6>
          </div>
          <i class="table__hr" />
        </template>
      </div>
      <div class="credit-buttons">
        <a
          :href="printUrl"
          target="_blank"
          :class="['button', 'button--print', isDark && 'print--dark']"
        >
          <spinner v-if="!printUrl" />
          <svg
            class="button__icon"
            style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
            viewBox="0 0 600 512"
          >
            <path
              d="M504.75 267.95l0 0c0,115.75 -94.16,209.91 -209.91,209.91 -115.1,0 -208.84,-93.11 -209.89,-207.96 0.07,-0.64 0.11,-1.29 0.11,-1.95 0,-9.43 -7.64,-17.07 -17.07,-17.07 -9.43,0 -17.07,7.64 -17.07,17.07l-0.14 0c0,134.89 109.15,244.05 244.06,244.05 134.89,0 244.05,-109.15 244.05,-244.05 0,-9.43 -7.64,-17.07 -17.07,-17.07 -9.43,0 -17.07,7.64 -17.07,17.07z"
            />
            <path
              d="M277.77 145.07l0 33.05 0 129.63 -52.51 -52.51c-6.66,-6.66 -17.47,-6.66 -24.14,0 -6.66,6.67 -6.66,17.48 0,24.14l81.29 81.29c3.43,3.42 7.94,5.09 12.43,5 4.48,0.09 9,-1.58 12.42,-5l81.29 -81.29c6.67,-6.66 6.67,-17.47 0,-24.14 -6.67,-6.66 -17.47,-6.66 -24.14,0l-52.5 52.51 0 -129.63 0 -33.05 0 -128c0,-9.43 -7.64,-17.07 -17.07,-17.07 -9.43,0 -17.07,7.64 -17.07,17.07l0 128z"
            />
          </svg>
          <span class="button__text">
            دانلود بن‌ها
          </span>
        </a>
      </div>
      <div :class="['desc', isDark && 'desc--dark']">
        <h4 class="desc__title">توضیحات</h4>
        <p class="desc__text" v-text="gift['desc']" />
        <p class="desc__text" v-text="gift['place']" />
      </div>
      <section :class="['time-wrapper', isDark && 'time--dark']">
        <p v-if="diffDay > 1">
          از
          <span>{{ localizeDate(gift['created_at']) }}</span>
          تا
          <span v-text="diffDay - 1" />
          روز
        </p>
        <p v-else>
          <span>{{ localizeDate(gift['created_at']) }}</span>
        </p>
        <count-down
          v-if="gift"
          :endDate="applyTimeZone(gift['expire_at'])"
          :permission="!isDark"
          class="countdown"
        />
        <div class="offBtn" v-if="!isDark" @click="modals['confirm'] = true">
          <svg class="offBtn__svg" viewBox="0 0 512 512" v-show="!btnLoading">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
        </div>
        <div class="offBtn offBtn--dark" v-if="isDark">
          <svg
            class="offBtn__svg"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
          >
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_1446895942480">
                <path
                  d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
                />
                <path
                  d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
                />
              </g>
            </g>
          </svg>
          <span class="offBtn__text">{{
            gift && localizeDate(gift['cancelled_at'])
          }}</span>
        </div>
      </section>
      <div class="tab" :class="['tab', isDark && 'tab--dark']">
        <div v-if="!isDark" class="tab__part" @click="enterGift('1')">
          <h6 class="tab__count" v-text="giftsCount['1']" />
          <span class="tab__status">بن فعال</span>
        </div>
        <i class="tab__sep" />
        <div class="tab__part" @click="enterGift('2')">
          <h6 class="tab__count" v-text="giftsCount['2']" />
          <span class="tab__status">بن مصرف شده</span>
        </div>
        <i class="tab__sep" />
        <div class="tab__part" @click="enterGift('3')">
          <h6 class="tab__count" v-text="giftsCount['3']" />
          <span class="tab__status">بن باطل شده</span>
        </div>
      </div>
    </section>
    <failed-mode @refresh="getItem" v-else />
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisableGift"
      text='آیا از "ابطال" بن ها مطمئن هستید؟'
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { disableGift } from '@service/GiftService'
import CountDown from '@common/CountDown'
import { useRouter } from 'vue-router'
import dateConvertor from '@composable/dateConvertor'
export default {
  name: 'GiftShow',
  props: ['id'],
  components: {
    CountDown
  },
  setup(props) {
    const store = useStore()
    const pageStatus = computed(() => store.state.gift.pageStatus)
    const flip = ref(false)
    const isDark = computed(() => store.state.gift.isDark)

    // Initial api call
    const gift = computed(() => store.state.gift.item)
    const giftsCount = computed(() => store.state.gift.giftItemsCount)
    const getItem = () => {
      store.dispatch('gift/fetchItem', { part: 'bon', id: props.id })
      store.dispatch('gift/fetchGiftItemsCount', props.id)
    }
    getItem()

    // Handle PDF
    const printUrl = computed(() => {
      if (!gift.value) return null
      return gift.value['file']
    })
    const downloadPDF = async () => {
      if (!printUrl.value) return

      const res = await fetch(printUrl.value)
      const blob = await res.blob({
        type: 'application/octetstream'
      })
      const url = window.URL || window.webkitURL
      const link = url.createObjectURL(blob)
      const a = document.createElement('a')
      const fileName = 'vipon-bon.pdf'
      a.setAttribute('download', fileName)
      a.setAttribute('href', link)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    // Disable gifts
    const modals = reactive({ confirm: false })

    const btnLoading = ref(false)
    const handleDisableGift = async () => {
      if (btnLoading.value) return

      btnLoading.value = true
      try {
        await disableGift(props.id)
        store.dispatch('addToast', {
          type: 'success',
          message: 'بن ها با موفقیت ابطال گردیدند.'
        })
        getItem()
        setTimeout(() => router.push({ name: 'GiftHome' }), 2000)
      } catch (ex) {
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
      } finally {
        modals['confirm'] = false
        btnLoading.value = false
      }
    }

    // Enter gifts
    const router = useRouter()
    const enterGift = (status) => {
      if (giftsCount.value[status] === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'داده‌ای برای نمایش موجود نیست.'
        })
      router.push({
        name: 'GiftStatus',
        params: { id: props.id, status }
      })
    }

    // Calculate difference in days
    const { getRemainingDay, localizeDate, applyTimeZone } = dateConvertor()
    const diffDay = computed(() => {
      return getRemainingDay(gift.value['created_at'], gift.value['expire_at'])
    })

    onBeforeUnmount(() => {
      store.dispatch('gift/clearItem')
    })

    return {
      flip,
      pageStatus,
      getItem,
      gift,
      printUrl,
      downloadPDF,
      modals,
      btnLoading,
      handleDisableGift,
      isDark,
      giftsCount,
      enterGift,
      diffDay,
      localizeDate,
      applyTimeZone
    }
  }
}
</script>

<style lang="scss" scoped>
.cmp-wrapper {
  padding-top: 75px;
  background: linear-gradient(to bottom, #fff 0, #fff 150px, #eee 220px);
  &.dark {
    background: #111;
  }
}
.gift-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}
.gift-top__img {
  width: 60px;
  max-height: 60px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.gift-top__title {
  text-align: right;
  color: #777;
  font-size: 16px;
}
.gift-top--dark .gift-top__img {
  filter: grayscale(1) invert(1);
  opacity: 0.3;
}

.desc {
  width: 100%;
  padding: 0 25px;
  margin: 15px 0;
}
.desc__title {
  color: #000;
  width: 100%;
  margin: 10px 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.desc__text {
  color: #686868;
  width: 100%;
  margin: 5px 0 0;
  text-align: justify;
  text-align-last: center;
  font-size: 12px;
  padding: 0 3px;
}
.desc--dark .desc__title {
  color: #777;
  font-weight: normal;
}

.countdown {
  margin-top: 0;
  padding-top: 10px;
}

.desc-btn {
  position: relative;
  margin: 20px 0;
  background-color: rgba(#fff, 1);
  padding: 0 15px 0 30px;
  color: #000;
  border-radius: 15px;
  width: 96%;
  text-align: center;
  outline: none;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}
.desc-btn__text {
  color: #777;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 85%;
  height: 50px;
  line-height: 50px;
  margin-right: 5px;
  font-weight: normal;
}
.desc-btn__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  margin: auto 0;
  width: 22px;
  height: 22px;
  fill: #aaa;
}
.desc-btn--dark {
  background-color: rgba(#181818, 0.8);
  color: #777;
}
.desc-btn--dark .desc-btn__text {
  color: #444;
}
.desc-btn--dark .desc-btn__arrow {
  fill: #333;
}
// .table {
//   // background: linear-gradient(to left, #fff 10%, #fff 100%);
//   border-color: #c8c8c8;
//   margin-top: 15px;
// }
// .table__hr {
//   background-color: #c8c8c8;
// }

// .table__row {
//   grid-template-columns: 1fr 1fr;
//   padding: 0 20px;
// }
.table--dark {
  background: #111;
  border-color: #333;
  .table__title {
    color: #777;
    font-weight: normal;
  }
  .table__title::after {
    color: #333;
  }
  .table__amount {
    font-weight: normal;
    color: #777;
  }
  .table__hr {
    background-color: #333;
  }
  .amount--rial::after {
    color: #333;
  }
  .cell--amount {
    color: #333;
    &::after {
      color: #333;
    }
  }
  .cell--title {
    color: #333;
    &::after {
      color: #333;
    }
  }
}

// .table {
//   margin-top: 15px;
//   border: none;
//   border-radius: 0;
// }
.table__row {
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
}

.cell--title {
  position: relative;
  top: 2px;
  font-size: 16px;
  margin: 0;
  // font-weight: bold;
  color: #333;
}

.cell--amount {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  color: #111;
  margin: 0;
  padding-top: 3px;
  font-weight: bold;
}

.cell--rial::after {
  color: #111;
  position: relative;
  content: 'R';
  font-size: 10px;
  right: -1px;
  top: -4px;
}

.table--dark .cell--amount {
  color: #333;
  &::after {
    color: #333;
  }
}

// change in common css
.credit-buttons {
  margin-bottom: 0;
}
.button--print {
  // border-radius: 100%;
  // width: 60px;
  // height: 60px;
  background-color: white;
}
.button--print .button__icon {
  fill: black;
  margin-left: 5px;
}
.button--print.print--dark {
  background-color: #202020;
}
.button--print.print--dark .button__icon {
  fill: #777;
}
.button--print.print--dark .button__text {
  color: #777;
}
.tab--dark {
  background-color: #111;
}

// table
.cell--title {
  position: relative;
  top: 2px;
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  color: #333;
  &::after {
    position: relative;
    margin-right: 5px;
    content: 'عدد';
    color: #8a8a8a;
    font-size: 16px;
    font-weight: normal;
  }
}
.cell--amount {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  color: #111;
  margin: 0;
  padding-top: 3px;
  font-weight: bold;
  &::after {
    color: #111;
    position: relative;
    content: 'R';
    font-size: 10px;
    right: -1px;
    top: -4px;
  }
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
