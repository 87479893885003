<template>
  <div ref="root" class="single-sats-wrapper" :class="{ dark: dark }">
    <div class="sats" @click="expandPanel">
      <div class="sats-info">
        <span class="right-line silver" />
        <div class="right-title">
          <div class="time">
            <p>
              <span>{{ date }}</span> <span>{{ hour }}</span>
            </p>
          </div>
        </div>
        <h3 v-text="price.toLocaleString()" />
      </div>
      <div class="down-btn" v-show="accordionOpen" @click="expandPanel">
        <svg
          @click="expandPanel"
          viewBox="0 0 451.847 451.847"
          :class="{ open: accordionOpen }"
          :fill="dark ? '#777' : '#999'"
        >
          <g>
            <path
              d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
            />
          </g>
        </svg>
      </div>
      <div class="bottom-sep" :class="{ plain: accordionOpen }">
        <span />
      </div>
    </div>
    <div
      class="accordion-1"
      :class="{ open: accordionOpen, close: !accordionOpen }"
    >
      <div
        class="accordion-2"
        :class="{
          open: accordionOpen2,
          close: !accordionOpen2
        }"
      >
        <div class="sats-details">
          <div class="table">
            <div class="stat-row">
              <h5>پذیرنده</h5>
              <h6 class="name">{{ receiverName }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// PLEASE COMPLETELY #FIXME
import { ref } from 'vue'
export default {
  name: 'GiftTransaction',
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    receiverName: {
      type: String
    },
    price: {
      type: Number,
      default: 0
    },
    date: {
      type: String
    },
    hour: {
      type: String
    }
  },
  setup() {
    const root = ref(null)
    const accordionOpen = ref(false)
    const accordionOpen2 = ref(false)
    const expandPanel = () => {
      accordionOpen.value = !accordionOpen.value
      if (accordionOpen.value) {
        setTimeout(() => {
          const fixedNavHeight = 50
          const { bottom } = root.value.getBoundingClientRect()
          const limit = window.innerHeight - fixedNavHeight - bottom
          if (limit < 0) {
            window.scrollBy({ top: -limit, behavior: 'smooth' })
          }
        }, 200)
      }
      accordionOpen2.value = !accordionOpen2.value
    }
    return { root, expandPanel, accordionOpen, accordionOpen2 }
  }
}
</script>

<style scoped lang="scss">
.single-sats-wrapper {
  padding: 0 0 0 0;
  margin: 0 0 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sats {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    background-color: #fff;
    border-radius: 0;
    padding: 20px 0 0;
    cursor: pointer;

    .sats-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // background-color: #1f1f1f;
      padding: 6px 20px 0 30px;
      .right-line {
        background-color: #fff;
        position: absolute;
        margin-left: 10px;
        width: 3px;
        height: 98%;
        top: 0px;
        bottom: 0;
        margin: auto 0;
        right: 0;
        z-index: 2;
        border-radius: 5px 0 0 5px;
        &.minus {
          background-color: #d40000;
        }
        &.plus {
          background-color: #00c209;
        }
        &.silver {
          background-color: #7e7e7e;
        }
      }
      .right-title {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h2 {
          position: relative;
          font-size: 16px;
          margin: 0;
          // font-weight: bold;
          color: #1f1f1f;
        }
        .time {
          direction: ltr;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3px;
          color: #777;

          p {
            // height: 100%;
            // line-height: 40px;
            margin: 0;
            padding: 0;
            word-spacing: 5px;
            // font-weight: bold;
            padding-top: 2px;
            span {
              color: #777;
            }
          }
        }
      }
      h3 {
        position: relative;
        direction: ltr;
        text-align: left;
        padding-top: 6px;
        margin: 0;
        font-size: 18px;
        &::after {
          position: absolute;
          content: 'R';
          color: #aaa;
          font-size: 10px;
          right: -8px;
          top: 7px;
        }
        &::before {
          position: absolute;
          content: '-';
          left: -12px;
        }
      }
    }
    .bottom-sep {
      height: 35px;
      padding: 0;
      margin: 0;
      position: relative;
      overflow: hidden;
      border-radius: 0;
      background-color: #fff;
      width: 100%;
      transition: background-color 0.3s ease;
      &.plain {
        border-radius: 0;
      }
      span {
        position: absolute;
        transition: all 0.3s ease;
        background-color: #e0e0e0;
        height: 1px;
        width: 100%;
        margin: 0 auto;
        right: 0;
        left: 0;
        bottom: 0px;
      }
    }

    .down-btn {
      position: absolute;
      cursor: pointer;
      width: 45px;
      height: 45px;
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: -15px;
      border-radius: 50%;
      background-color: #fff;
      z-index: 1;
      transform: scaleX(1.5);
      box-shadow: 0px 7px 6px -2px rgba(0, 0, 0, 0.2);
      transition: background-color 0.3s ease;
      &.dark {
        background-color: rgb(31, 31, 31);
      }

      svg {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        transform: scale(0.33, 0.5);
        &.open {
          transform: rotate(180deg) scale(0.33, 0.5);
        }
      }
    }
  }

  .accordion-1 {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    margin: 0 0 0;
    padding: 0;
    max-height: 0;
    &.open {
      max-height: 700px;
    }
    &.close {
      max-height: 0;
    }
    .accordion-2 {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 0;
      padding: 15px 20px 15px;
      background-color: #1a1a1a;
      max-height: 700px;
      &.open {
        opacity: 1;
      }
      &.close {
        opacity: 0;
      }
      .sats-details {
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        .table {
          margin: 30px 0;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 10px;
          .stat-row {
            padding: 0 4%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            h5 {
              color: #777;
              font-size: 16px;
              margin: 0 0 0 6px;
            }
            h6 {
              font-size: 16px;
              margin-bottom: 0;
              // padding-top: 2px;
              // direction: ltr;
              // text-align: left;
              position: relative;
              color: #eee;
            }
          }
        }
      }
    }
  }

  &.dark {
    .sats {
      background-color: #131313;
    }

    .sats .sats-info h3 {
      color: #777;
      font-weight: normal;
      &::after {
        color: #777777;
      }
    }
    .sats .sats-info .right-title h2 {
      color: #777;
      font-weight: normal;
    }

    .sats .bottom-sep {
      background-color: #131313;
    }

    .sats .bottom-sep span {
      background-color: #1c1c1c;
    }

    .sats .down-btn {
      background-color: #131313;
      box-shadow: none;
    }

    .accordion-1 .accordion-2 .sats-details .plan-name h5 {
      color: #777;
    }

    .accordion-1 .accordion-2 .sats-details .plan-name h6 {
      color: #777;
    }

    .accordion-1 .accordion-2 .sats-details .plan-name span {
      color: #777;
    }

    .accordion-1 .accordion-2 .sats-details .trans-info .stat-row h5 {
      color: #777777;
    }

    .accordion-1 .accordion-2 .sats-details .trans-info .stat-row h6 {
      color: #777777;
      &.rial::after {
        color: #777777;
      }
      &.percent::after {
        color: #777777;
      }
    }
  }
}
</style>
