<template>
  <section class="list">
    <gift-single
      v-for="gift in gifts"
      :key="gift['id']"
      :count="gift['count']"
      :image="gift['image']"
      :organ="gift['organization']"
      :is-dark="gift['status'] !== '1'"
      @click="enterGift(gift['id'])"
    />
  </section>
  <empty-part
    desc-top="بن خریدی"
    desc-bottom="بن خرید"
    v-if="pageStatus == 'resolved' && gifts.length === 0"
  />
  <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
  <loader v-if="pageStatus == 'loading'" />
  <observer @intersect="intersected" />
</template>

<script>
import { computed, onBeforeUnmount } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import EmptyPart from '@common/EmptyPart'
import GiftSingle from './subComponents/GiftSingle'

export default {
  name: 'GiftHome',
  components: {
    GiftSingle,
    EmptyPart
  },
  setup() {
    // Fetch offers
    const store = useStore()
    store.dispatch('gift/fetchItems', { part: 'bon' })
    const gifts = computed(() => store.state.gift.items)
    const pageStatus = computed(() => store.state.gift.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('gift/fetchItems', { part: 'bon' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterGift = (id) => {
      router.push({ name: 'GiftShow', params: { id } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('gift/clearItems'))

    // ------- //
    return { gifts, pageStatus, intersected, enterGift }
  }
}
</script>
