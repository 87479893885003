<template>
  <div :class="['gift-card-wrapper', innerCard && 'card--inner']">
    <div :class="['card', isDark && 'card--dark']">
      <div class="card__top">
        <svg
          width="600px"
          height="420px"
          viewBox="0 0 600 420"
          class="card__barcode"
        >
          <g id="Layer_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <polygon class="fil0" points="38.13,305.04 38.13,0 0,0 0,305.04 " />
            <rect class="fil0" x="57.19" width="25.42" height="305.04" />
            <rect class="fil0" x="163.75" width="25.42" height="305.04" />
            <rect class="fil0" x="208.24" width="38.13" height="305.04" />
            <rect class="fil0" x="131.98" width="19.07" height="305.04" />
            <rect class="fil0" x="100.86" width="14.84" height="411.63" />
            <rect class="fil0" x="259.08" width="19.07" height="305.04" />
            <polygon
              class="fil0"
              points="388.29,0 348.05,0 348.05,305.04 388.29,305.04 399.95,305.04 424.31,305.04 424.31,0 399.95,0 "
            />
            <rect class="fil0" x="290.85" width="31.78" height="305.04" />
            <rect class="fil0" x="553.69" width="38.13" height="305.04" />
            <rect class="fil0" x="530.98" width="11.39" height="412.71" />
            <polygon
              class="fil0"
              points="489.11,0 449.73,0 449.73,305.04 489.11,305.04 496.5,305.04 516.2,305.04 516.2,0 496.5,0 "
            />
            <path
              class="fil0"
              d="M40.9 378.95c0,21.76 -8.07,33.76 -22.24,33.76 -12.5,0 -20.97,-11.71 -21.16,-32.87 0,-21.46 9.25,-33.26 22.24,-33.26 13.49,-0.01 21.16,12 21.16,32.37zm-34.74 0.98c0,16.64 5.12,26.08 12.99,26.08 8.86,0 13.09,-10.33 13.09,-26.67 0,-15.75 -4.04,-26.08 -12.99,-26.08 -7.57,0 -13.09,9.25 -13.09,26.67z"
            />
            <polygon
              class="fil0"
              points="68.68,355.73 68.48,355.73 57.36,361.73 55.68,355.14 69.66,347.65 77.04,347.65 77.04,411.62 68.68,411.62 "
            />
            <path
              class="fil0"
              d="M138.04 411.62l0 -5.31 6.78 -6.59c16.34,-15.55 23.72,-23.82 23.82,-33.47 0,-6.49 -3.15,-12.5 -12.7,-12.5 -5.8,0 -10.63,2.95 -13.58,5.42l-2.76 -6.11c4.43,-3.74 10.73,-6.49 18.12,-6.49 13.77,0 19.58,9.44 19.58,18.6 0,11.81 -8.56,21.36 -22.05,34.35l-5.12 4.73 0 0.19 28.74 0 0 7.18 -40.83 0 0 0z"
            />
            <path
              class="fil0"
              d="M191.6 401.68c2.46,1.58 8.16,4.04 14.17,4.04 11.13,0 14.57,-7.08 14.47,-12.4 -0.1,-8.95 -8.17,-12.8 -16.54,-12.8l-4.83 0 0 -6.49 4.83 0c6.29,0 14.27,-3.25 14.27,-10.82 0,-5.12 -3.25,-9.64 -11.22,-9.64 -5.13,0 -10.04,2.26 -12.8,4.23l-2.26 -6.3c3.35,-2.47 9.85,-4.92 16.73,-4.92 12.6,0 18.31,7.48 18.31,15.25 0,6.6 -3.94,12.21 -11.81,15.06l0 0.19c7.87,1.58 14.27,7.49 14.27,16.45 0,10.23 -7.97,19.19 -23.33,19.19 -7.18,0 -13.47,-2.26 -16.63,-4.33l2.37 -6.71z"
            />
            <path
              class="fil0"
              d="M267.8 411.62l0 -17.41 -29.72 0 0 -5.72 28.53 -40.85 9.36 0 0 39.77 8.95 0 0 6.79 -8.95 0 0 17.42 -8.17 0 0 0zm0 -24.2l0 -21.36c0,-3.34 0.1,-6.69 0.3,-10.04l-0.3 0c-1.97,3.74 -3.55,6.49 -5.31,9.45l-15.66 21.75 0 0.2 20.97 0 0 0z"
            />
            <path
              class="fil0"
              d="M330.72 354.94l-24.41 0 -2.47 16.44c1.48,-0.2 2.86,-0.4 5.22,-0.4 4.92,0 9.85,1.09 13.78,3.44 5.02,2.86 9.15,8.37 9.15,16.45 0,12.5 -9.94,21.84 -23.82,21.84 -6.99,0 -12.88,-1.97 -15.94,-3.93l2.17 -6.6c2.65,1.58 7.87,3.55 13.67,3.55 8.17,0 15.16,-5.31 15.16,-13.88 -0.1,-8.27 -5.61,-14.16 -18.4,-14.16 -3.64,0 -6.5,0.39 -8.86,0.68l4.13 -30.71 30.61 0 0 7.28 0.01 0 0 0z"
            />
            <path
              class="fil0"
              d="M393.34 353.76c-1.77,-0.1 -4.04,0 -6.49,0.39 -13.59,2.26 -20.78,12.21 -22.25,22.74l0.3 0c3.05,-4.04 8.36,-7.39 15.45,-7.39 11.32,0 19.29,8.18 19.29,20.68 0,11.71 -7.97,22.53 -21.26,22.53 -13.68,0 -22.64,-10.62 -22.64,-27.26 0,-12.6 4.53,-22.54 10.83,-28.84 5.31,-5.21 12.4,-8.46 20.47,-9.45 2.56,-0.39 4.72,-0.49 6.29,-0.49l0 7.09 0.01 0 0 0zm-2.46 36.9c0,-9.15 -5.22,-14.66 -13.19,-14.66 -5.21,0 -10.04,3.25 -12.39,7.87 -0.6,0.98 -0.99,2.26 -0.99,3.84 0.2,10.53 5.02,18.31 14.08,18.31 7.47,0 12.49,-6.2 12.49,-15.36z"
            />
            <path
              class="fil0"
              d="M562.14 405.63c1.76,0.1 4.03,0 6.49,-0.39 13.59,-2.27 20.78,-12.21 22.24,-22.74l-0.29 0c-3.05,4.04 -8.36,7.38 -15.45,7.38 -11.32,0 -19.3,-8.17 -19.3,-20.67 0,-11.71 7.98,-22.54 21.27,-22.54 13.68,0 22.64,10.63 22.64,27.27 0,12.59 -4.53,22.53 -10.83,28.83 -5.31,5.22 -12.4,8.47 -20.48,9.45 -2.55,0.4 -4.72,0.49 -6.29,0.49l0 -7.08 0 0 0 0zm2.46 -36.91c0,9.15 5.22,14.67 13.18,14.67 5.22,0 10.04,-3.25 12.4,-7.88 0.6,-0.98 0.99,-2.25 0.99,-3.83 -0.2,-10.54 -5.02,-18.31 -14.08,-18.31 -7.47,0 -12.49,6.2 -12.49,15.35z"
            />
            <polygon
              class="fil0"
              points="449.85,347.65 449.85,353.36 422,411.62 413.05,411.62 440.8,355.03 440.8,354.84 409.5,354.84 409.5,347.65 449.85,347.65 "
            />
            <path
              class="fil0"
              d="M459.04 395.39c0,-8.07 4.82,-13.78 12.69,-17.12l-0.1 -0.3c-7.08,-3.34 -10.14,-8.86 -10.14,-14.37 0,-10.14 8.57,-17.03 19.78,-17.03 12.4,0 18.6,7.78 18.6,15.75 0,5.41 -2.65,11.22 -10.53,14.96l0 0.29c7.98,3.15 12.9,8.76 12.9,16.54 0,11.12 -9.55,18.6 -21.75,18.6 -13.39,0 -21.45,-7.97 -21.45,-17.32zm34.45 -0.4c0,-7.77 -5.42,-11.52 -14.08,-13.97 -7.49,2.17 -11.52,7.08 -11.52,13.18 -0.29,6.5 4.63,12.21 12.8,12.21 7.78,0 12.8,-4.83 12.8,-11.42zm-23.82 -31.99c0,6.4 4.82,9.85 12.2,11.82 5.51,-1.88 9.74,-5.81 9.74,-11.62 0,-5.11 -3.04,-10.43 -10.82,-10.43 -7.19,0 -11.12,4.72 -11.12,10.23z"
            />
          </g>
        </svg>
        <h3 class="card__code">{{ code }}</h3>
      </div>
      <i class="card__sep" />
      <div class="card__bottom">
        <div class="card__row">
          <h4 class="card__row-title">مبلغ</h4>
          <h5 class="card__price ">{{ amount?.toLocaleString() }}</h5>
        </div>
        <div class="card__row">
          <h4 class="card__row-title">موجودی</h4>
          <h5 class="card__price price--gold">
            {{ remain?.toLocaleString() }}
          </h5>
        </div>
      </div>
      <i class="card__circle circle--left" />
      <i class="card__circle circle--right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GiftCard',
  props: {
    code: String,
    remain: [String, Number],
    amount: [String, Number],
    isDark: {
      type: Boolean,
      default: false
    },
    innerCard: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-card-wrapper {
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.card {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  display: grid;
  position: relative;
  grid-template-rows: 85px 2px 85px;
  background-color: #fff;
}

.card__top {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}

.card__bottom {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.card__sep {
  border-top: 1.6px dashed #ccc;
  width: 85%;
  margin: 0 auto;
}

.card__barcode {
  width: 60px;
  max-height: 40px;
}

.card__code {
  color: #111;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}
.card__row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.card__row-title {
  text-align: left;
  position: relative;
  text-align: center;
  margin: 0 0 4px;
  color: #111;
  font-size: 16px;
}
.card__price {
  text-align: left;
  position: relative;
  text-align: center;
  margin: 0;
  color: #777;
  &::after {
    position: relative;
    content: 'R';
    font-size: 10px;
    right: -1px;
    top: -4px;
  }
}
.price--gold {
  color: #c69d4e;
}
.card__circle {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #eee;
  top: calc(50% - 12px);
  &.circle--left {
    left: -12px;
  }
  &.circle--right {
    right: -12px;
  }
}
.card--dark {
  background-color: #181818;
}
.card--dark .card__circle {
  background-color: #080808;
}
.card--dark .card__remain {
  color: #555;
}
.card--dark .card__price {
  color: #555;
}
.card--dark .card__code {
  color: #777;
}
.card--dark .card__sep {
  border-top-color: #303030;
}
.card--dark .card__barcode {
  fill: #333;
}
.card--dark .card__row-title {
  color: #555;
}
.card--inner {
  width: calc(100% - 20px);
}
.card--inner .card {
  cursor: default;
}
</style>
