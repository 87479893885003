<template>
  <div class="gift-show-wrapper" :class="isDark && 'wrapper--dark'">
    <navbar msg="وضعیت بن خرید" />
    <section class="load-pending load-pending--show" v-if="!resolved">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div :class="['gift-top', isDark && 'gift-top--dark']" v-if="gift">
        <img class="gift-top__img" :src="gift['organization_image']" />
        <h6 class="gift-top__title" v-text="gift['organization']" />
      </div>
      <gift-card
        v-if="gift"
        style="width: 96%"
        :code="gift['code']"
        :amount="gift['amount']"
        :remain="gift['remain']"
        :isDark="isDark"
      />
      <div :class="['desc', isDark && 'desc--dark']">
        <h4 class="desc__title">توضیحات</h4>
        <p class="desc__text">{{ gift['desc'] }}</p>
      </div>
      <section :class="['time-wrapper', isDark && 'time--dark']">
        <p v-if="diffDay > 1">
          از
          <span>{{ localizeDate(gift['created_at']) }}</span>
          تا
          <span v-text="diffDay - 1" />
          روز
        </p>
        <p v-else>
          <span>{{ localizeDate(gift['created_at']) }}</span>
        </p>
        <count-down
          :endDate="applyTimeZone(gift['expire_at'])"
          :permission="!isDark"
          class="countdown"
        />
        <div class="offBtn" v-if="!isDark" @click="modals['confirm'] = true">
          <svg class="offBtn__svg" viewBox="0 0 512 512">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
        </div>
        <div class="offBtn offBtn--dark" v-if="gift['status'] == 3">
          <svg class="offBtn__svg" viewBox="0 0 512 512">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
          <span class="offBtn__text">
            {{ localizeDate(gift['cancelled_at']) }}
          </span>
        </div>
      </section>
      <h4 class="cmp-title" v-if="!isDark">دریافت مبلغ</h4>
      <form
        v-if="!isDark"
        novalidate
        class="form-wrapper"
        @submit.prevent="receiveGift"
      >
        <base-input-number
          label="مبلغ"
          rial
          star
          style="margin-top:15px;"
          v-model="formItems['price']"
        />
        <div class="credit-buttons">
          <button type="submit" class="button button--black">
            <span class="button__text">
              دریافت
            </span>
            <spinner v-if="btnLoading" />
          </button>
        </div>
      </form>
      <div :class="['tab', isDark && 'tab--dark']" v-if="gift">
        <a class="tab__part" @click="enterTransactions">
          تراکنش ها
        </a>
      </div>
    </section>
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="giftBtnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisableGift"
      text='آیا از "ابطال" این بن مطمئن هستید؟'
    />
    <fixed-footer />
  </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dateConvertor from '@composable/dateConvertor'
import { price, schemaMaker, validateForms } from '@valid/JoiValidation'
import { disableGiftItem } from '@service/GiftService'
import { getCustomerGift, receiveGiftPrice } from '@service/CustomerService'
import BaseInputNumber from '@common/BaseInputNumber'
import CountDown from '@common/CountDown'
import GiftCard from './subComponents/GiftCard'

export default {
  name: 'GiftInquiry',
  props: ['code'],
  components: {
    CountDown,
    GiftCard,
    BaseInputNumber
  },
  setup(props) {
    const modals = reactive({
      confirm: false
    })
    const store = useStore()
    const router = useRouter()
    const btnLoading = ref(false)
    const gift = ref(null)
    const isDark = ref(false)
    const giftBtnLoading = ref(false)
    const resolved = computed(() => !!gift.value)

    const getGiftInfo = async () => {
      try {
        const { data } = await getCustomerGift({ code: props.code })
        if (data.status !== '1') {
          isDark.value = true
          store.commit('THEME_IS_DARK')
        }
        gift.value = data
      } catch (ex) {
        console.log(ex)
        store.dispatch('addToast', {
          type: 'error',
          message: 'مشکلی در ارتباط با سرور پیش آمده است.'
        })
      }
    }

    const formItems = reactive({
      price: ''
    })
    const validPrice = schemaMaker({
      price: price('بن دریافتی', 10000)
    })

    const receiveGift = async () => {
      if (btnLoading.value) return
      const errors = validateForms({ price: formItems['price'] }, validPrice)

      if (errors && errors.length !== 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          store.dispatch('addToast', message)
        }
        return
      }

      btnLoading.value = true
      try {
        await receiveGiftPrice({ code: props.code, price: formItems['price'] })
        store.dispatch('addToast', {
          type: 'success',
          message: 'مبلغ مورد نظر با موفقیت از بن دریافت شد.'
        })
        formItems['price'] = ''
        btnLoading.value = false
        getGiftInfo()
      } catch (ex) {
        if (ex.response && ex.response.status == 406) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'مبلغ دریافتی از موجودی بن بیشتر می‌باشد.'
          })
        }
        btnLoading.value = false
      }
    }

    // Disabling current giftItem
    const handleDisableGift = async () => {
      giftBtnLoading.value = true
      try {
        await disableGiftItem(gift.value['id'])
        store.dispatch('addToast', {
          type: 'success',
          message: 'بن با موفقیت ابطال گردید.'
        })
        getGiftInfo()
        setTimeout(() => router.push({ name: 'Home' }), 2000)
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
        }
      } finally {
        giftBtnLoading.value = false
        modals['confirm'] = false
      }
    }

    // Entering transactions
    const enterTransactions = () => {
      if (gift.value['amount'] == gift.value['remain'])
        return store.dispatch('addToast', {
          type: 'error',
          message: 'تراکنشی انجام نشده است.'
        })
      router.push({
        name: 'GiftTransactions',
        params: { code: props.code, id: gift.value['id'] }
      })
    }

    // initial api call
    getGiftInfo()

    // time functions
    const { localizeDate, getRemainingDay, applyTimeZone } = dateConvertor()
    const diffDay = computed(() => {
      return getRemainingDay(gift.value['created_at'], gift.value['expire_at'])
    })
    return {
      gift,
      btnLoading,
      localizeDate,
      getRemainingDay,
      applyTimeZone,
      receiveGift,
      formItems,
      isDark,
      handleDisableGift,
      giftBtnLoading,
      enterTransactions,
      resolved,
      modals,
      diffDay
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-show-wrapper {
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 50px;
  background-color: #eee;
}
.wrapper--dark {
  background-color: #080808;
}
// gift-top block
.gift-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 20px;
}
.gift-top__img {
  width: 60px;
  max-height: 60px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.gift-top__title {
  text-align: right;
  color: #777;
  font-size: 16px;
}
.gift-top--dark .gift-top__img {
  filter: grayscale(1) invert(1);
  opacity: 0.3;
}
// description
.desc {
  width: 100%;
  padding: 0 25px;
  margin: 15px 0;
}
.desc__title {
  color: #000;
  width: 100%;
  margin: 10px 0 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.desc__text {
  color: #686868;
  width: 100%;
  margin: 5px 0 0;
  text-align: justify;
  text-align-last: center;
  font-size: 12px;
  padding: 0 3px;
}
.desc--dark .desc__title {
  color: #777;
  font-weight: normal;
}
.organ {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.organ__img {
  width: 80px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.organ__title {
  width: 100%;
  text-align: center;
  font-size: 18px;
}
// change in common css
.cmp-title {
  width: 100%;
  text-align: center;
  margin: 50px 0 5px;
  font-size: 22px;
}
.tab {
  padding: 0;
  width: 96%;
  margin: 10px 0 20px;
}
.tab__part {
  width: 100%;
  margin: 0;
  font-size: 14px;
}
.tab--dark {
  background-color: #080808;
  margin-top: 70px;
}
.tab--dark .tab__part {
  color: #555;
}
.countdown {
  margin-top: 0;
  padding-top: 10px;
  width: 100%;
}
.credit-buttons {
  margin: 10px 0 0;
}
.button--black {
  width: 100%;
  .button__text {
    color: #c69d4e;
  }
}
.form-wrapper {
  width: 96%;
  background-color: #fff;
  margin: 10px 0;
  padding: 10px 20px 25px;
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(#000, 0.1);
}
</style>
