<template>
  <div>
    <img v-bind="$attrs" :src="src" v-show="flip" @load="flip = !flip" />
    <img v-bind="$attrs" src="@img/preload-logo.svg" v-if="!flip" />
  </div>
</template>

<script>
export default {
  name: 'BaseImg',
  inheritAttrs: false,
  props: {
    src: {
      type: String
    },
    css: {
      type: String
    }
  },
  data() {
    return {
      flip: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
