<template>
  <div class="gift-new-wrapper">
    <form novalidate @submit.prevent="submitForm" class="form-wrapper">
      <!-- GIFT SET PART -->
      <div class="bon-count-input" v-for="set in giftSet" :key="set['id']">
        <a
          class="gift-btn"
          :class="{ active: set['giftCount'] !== 0 }"
          @click="
            () => {
              modals['giftCount'] = true
              selectedSet = set
            }
          "
        >
          {{ set['giftCount'] !== 0 ? set['giftCount'] : 'انتخاب تعداد ' }}
          <span v-if="set['giftCount'] !== 0">عدد</span>
        </a>
        <base-input-number
          label="مبلغ هر بن"
          rial-text="ریالی"
          style="width:65%"
          animateType="static"
          v-model="set['giftPrice']"
        />
      </div>
      <a class="circleBtn" v-if="giftSet.length < 5" @click="addSet">
        <svg
          height="512pt"
          width="512pt"
          viewBox="0 0 512 512"
          class="circleBtn__img"
        >
          <path
            d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
          />
          <path
            d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
          />
        </svg>
      </a>
      <modal
        :is-open="modals['giftCount']"
        @modalClosed="modals['giftCount'] = false"
        title="تعداد بن"
      >
        <ul class="items-wrapper">
          <li v-for="item in items" :key="item">
            <a
              :class="{
                active: selectedSet && selectedSet['giftCount'] === item
              }"
              @click="
                () => {
                  selectedSet.giftCount = item
                  modals['giftCount'] = false
                }
              "
            >
              {{ item }}
              <span>عدد</span>
            </a>
          </li>
        </ul>
      </modal>
      <!-- ORGAN PART  -->
      <div class="credit-buttons">
        <a class="button button--form" @click="modals['organ'] = true">
          <img
            class="button__icon button--img"
            v-if="organBtnImg"
            :src="organBtnImg"
          />
          <span :class="['button__text', formItems['organ'] && 'text--organ']">
            {{ organBtnTitle }}
          </span>
        </a>
      </div>
      <modal :is-open="modals['organ']" @modalClosed="modals['organ'] = false">
        <div class="organ-modal">
          <div class="credit-buttons" style="margin:20px 0">
            <a
              class="button button--modal"
              @click="
                () => {
                  modals['organ'] = false
                  modals['newOrgan'] = true
                  organAction = 'new'
                }
              "
            >
              <svg class="button__icon" viewBox="0 0 512 512">
                <path
                  d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                />
                <path
                  d="m256 384c-8.832031 0-16-7.167969-16-16v-224c0-8.832031 7.167969-16 16-16s16 7.167969 16 16v224c0 8.832031-7.167969 16-16 16zm0 0"
                />
              </svg>
            </a>
          </div>
          <div class="organ">
            <template v-for="organ in organs" :key="organ">
              <div
                class="organ__row"
                @click.self="
                  () => {
                    formItems['organ'] = organ['id']
                    organBtnTitle = organ['name']
                    organBtnImg = organ['image']
                    modals['organ'] = false
                  }
                "
              >
                <base-img
                  :src="organ['image']"
                  style="width: 40px; height: 40px; border-radius: 5px; pointer-events: none;"
                />
                <h5 class="organ__title" v-text="organ['name']" />
                <!-- <i class="organ__vr"></i> -->
                <a class="organ__btn-edit" @click="handleEditOrgan(organ)">
                  <svg viewBox="0 0 512 512">
                    <path
                      d="M180.36 73.78c0,-27.71 22.65,-50.35 50.35,-50.35l17.62 0 15.34 0 17.62 0c27.7,0 50.35,22.64 50.35,50.35l0 248.72 0 37.95 0 3.93c-21.37,37.05 -42.73,74.11 -64.1,111.16 -9.81,19.64 -14.65,15.07 -23.29,-0.44l-63.89 -111.23 0 -3.42 0 -24.99 0 -261.68zm125.01 283.59l0 -188.65 -98.74 0 0 188.19 49.37 86.12 49.37 -85.66zm-98.74 -214.92l98.74 0 0 -68.67c0,-13.23 -10.84,-24.07 -24.08,-24.07l-17.62 0 -15.34 0 -17.62 0c-13.24,0 -24.08,10.84 -24.08,24.07l0 68.67z"
                    />
                  </svg>
                </a>
                <i class="organ__vr"></i>
                <a
                  class="organ__btn-delete"
                  @click="handleDeleteOrgan(organ['id'])"
                  >&times;</a
                >
              </div>
              <!-- <i class="organ__hr" /> -->
            </template>
            <loader v-show="modalLoading" />
          </div>
        </div>
      </modal>
      <modal
        :is-open="modals['newOrgan']"
        @modalClosed="
          () => {
            modals['newOrgan'] = false
            modals['organ'] = true
          }
        "
        height="350px"
      >
        <div class="organ-modal organ-modal--linear" style="padding-top: 0px">
          <form
            novalidate
            class="form-wrapper "
            @submit.prevent="submitOrganForm"
          >
            <div class="organ-forms">
              <our-input
                label="نام شرکت / فروشگاه"
                width="100%"
                animateType="remove"
                starLabel
                pr="5px"
                style="margin-bottom: 20px"
                v-model="newOrganTitle"
              />
              <div
                id="img-dropzone"
                class="dropzone-container"
                @dragover.prevent
                @drop.prevent
              >
                <div class="file-wrapper">
                  <div class="logo" v-if="!croppedImgBase64">
                    <span>لوگو</span>
                    <span>LOGO</span>
                  </div>
                  <img v-if="croppedImgBase64" :src="croppedImgBase64" />
                  <input
                    type="file"
                    name="file-input"
                    accept="image/*"
                    @change="
                      (e) => {
                        modals['newOrgan'] = false
                        handleStartCropper(e)
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div class="credit-buttons buttons--filter">
              <button
                type="button"
                class="button button--filter"
                @click="
                  () => {
                    modals['newOrgan'] = false
                    modals['organ'] = true
                  }
                "
              >
                <svg class="button__icon" viewBox="0 0 512 512">
                  <path
                    d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
                  />
                </svg>
              </button>
              <button
                type="submit"
                class="button button--filter button--accept"
              >
                <svg
                  class="button__icon"
                  viewBox="0 0 512 512"
                  v-show="!btnLoading"
                >
                  <path
                    d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
                  />
                </svg>
                <spinner v-if="btnLoading" bg-color="#ddd" />
              </button>
            </div>
          </form>
        </div>
      </modal>
      <dropzone-modal
        :is-open="cropperModal"
        @modalClosed="cropperModal = false"
      >
        <div class="upload-img-wrapper">
          <div class="round-btns cropper--btns">
            <a
              class="round-btn"
              @click="
                () => {
                  getBothOutputs()
                  $nextTick(() => {
                    modals['newOrgan'] = true
                  })
                }
              "
            >
              <svg class="round-btn__icon" viewBox="0 0 512 512">
                <path
                  d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
                />
              </svg>
            </a>
            <a class="round-btn" @click="resetCropper">
              <svg class="round-btn__icon" viewBox="0 0 512 512">
                <path
                  d="M357.1 154.91l-0.01 -0.01c-5.23,-5.23 -13.8,-5.24 -19.04,0l-82.05 82.05 -82.05 -82.05c-5.24,-5.24 -13.81,-5.23 -19.04,0l-0.01 0.01c-5.23,5.23 -5.24,13.8 0,19.04l82.05 82.05 -82.05 82.05c-5.24,5.24 -5.23,13.81 0,19.04l0.01 0.01c5.23,5.23 13.8,5.24 19.04,0l82.05 -82.05 82.05 82.05c5.24,5.24 13.81,5.23 19.04,0l0.01 -0.01c5.23,-5.23 5.24,-13.8 0,-19.04l-82.05 -82.05 82.05 -82.05c5.24,-5.24 5.23,-13.81 0,-19.04z"
                />
              </svg>
            </a>
          </div>
          <div class="img-wrapper">
            <img :src="resizedImg" ref="imgRef" />
          </div>
        </div>
      </dropzone-modal>
      <!-- EXPLANATION -->
      <div class="bta-wrapper">
        <box-textarea
          label="توضیحات"
          width="100%"
          animateType="remove"
          v-model="formItems['explanation']"
        />
      </div>
      <!-- DATE PART -->
      <div class="credit-buttons">
        <a class="button button--form" @click="modals['day'] = true">
          <span :class="['button__text', !!formItems['expireDay'] && 'active']">
            {{ btnDay }}
          </span>
          <span
            class="button__text active--detail"
            v-show="formItems['expireDay'] > 1"
          >
            روز
          </span>
        </a>
      </div>
      <modal
        :is-open="modals['day']"
        @modalClosed="modals['day'] = false"
        title="تاریخ اعتبار"
      >
        <ul class="items-wrapper">
          <li v-for="(day, idx) in days" :key="day">
            <a
              :class="day + 1 === formItems['expireDay'] && 'active'"
              @click="
                () => {
                  if (idx === 0) formItems['expireDay'] = 1
                  else formItems['expireDay'] = day + 1
                  modals['day'] = false
                }
              "
            >
              {{ day }} <span v-if="idx > 0">روز</span>
            </a>
          </li>
        </ul>
      </modal>
      <!-- <div class="table table--form">
        <div class="table__row">
          <h6 class="table__cell cell--title">
            تعداد بن
          </h6>
          <h6 class="table__cell cell--amount">
            {{ giftSum.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            ایجاد هر بن
          </h6>
          <h6 class="table__cell cell--price ">
            {{ giftTablePrice }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            هزینه کل
          </h6>
          <h6 class="table__cell cell--price ">
            {{ giftFinalPrice.toLocaleString() }}
          </h6>
        </div>
      </div> -->
      <div class="credit-buttons" style="margin-top: 0;">
        <button class="button button--black">
          <span class="button__text">
            ایجاد بن‌ ها
          </span>
          <spinner v-if="btnLoading" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  schemaMaker,
  number,
  reqString,
  validateForms,
  image,
  modalNumber,
  maxNumber
} from '@valid/JoiValidation'
import { addOrgan, addGift, deleteOrgan, editOrgan } from '@service/GiftService'
import { useStore } from 'vuex'
import { imgMixin } from '@mixin/imgMixin'
import BaseInputNumber from '@common/BaseInputNumber'
import OurInput from '@common/OurInput'
import BoxTextarea from '@common/BoxTextarea'
import DropzoneModal from '@common/DropzoneModal'
import { useRouter } from 'vue-router'
import { computed, reactive, ref } from 'vue'
import imgCropper from '@composable/imgCropper'
import dateConvertor from '@composable/dateConvertor'
import BaseImg from '@common/BaseImg'

export default {
  name: 'GiftNew',
  mixins: [imgMixin],
  components: {
    BaseInputNumber,
    BoxTextarea,
    DropzoneModal,
    OurInput,
    BaseImg
  },
  setup() {
    // Define main items
    const store = useStore()
    const router = useRouter()
    const modals = reactive({
      giftCount: false,
      organ: false,
      newOrgan: false,
      day: false,
      cropper: false
    })
    const btnLoading = ref(false)
    const isDone = ref(false)
    const formItems = reactive({
      explanation: '',
      expireDay: '',
      organ: ''
    })
    const items = reactive([...Array.from({ length: 100 }, (_, i) => i + 1)])
    // Day modals
    const days = ['امروز', ...Array.from({ length: 100 }, (_, i) => i + 1)]
    const btnDay = computed(() => {
      const day = formItems['expireDay']
      if (day) {
        return day === 1 ? 'امروز' : day - 1
      } else return 'تاریخ اعتبار'
    })
    // items from store
    const organs = computed(() => store.state.gift.organs)
    const modalLoading = computed(() => store.state.gift.modalLoading)
    // initial api calls
    store.dispatch('gift/fetchOrgans')

    // handle gift sets
    let currentSetId = 1
    const selectedSet = ref(null)
    const giftSet = reactive([{ id: 1, giftCount: 0, giftPrice: '' }])
    const addSet = () => {
      const id = currentSetId++
      giftSet.push({
        id,
        giftCount: 0,
        giftPrice: ''
      })
    }
    // schema for validating gifts
    const giftSchema = schemaMaker({
      giftCount: number('بن', 1, 'حداقل تعداد "بن" 1 عدد می‌باشد.'),
      giftPrice: reqString('مبلغ بن')
    })
    const giftValidSum = schemaMaker({
      sum: maxNumber('مجموع بن ها', 100, `حداکثر تعداد "ایجاد بن" 100 می‌باشد.`)
    })
    const giftTablePrice = 500
    const giftSum = computed(() =>
      giftSet.reduce((acc, curr) => {
        return acc + curr['giftCount']
      }, 0)
    )
    const giftFinalPrice = computed(() => giftTablePrice * giftSum.value)
    const validateGifts = () => {
      let errors = []
      if (giftSum.value > 100) {
        const sumError = validateForms({ sum: giftSum.value }, giftValidSum)
        errors = [...sumError]
      }
      const filledOneItem = giftSet.some(
        (gift) => !(gift['giftCount'] === 0 && gift['giftPrice'] == '')
      )
      if (!filledOneItem)
        for (let gift of giftSet) {
          const { giftCount, giftPrice } = gift
          const giftErrors = validateForms({ giftCount, giftPrice }, giftSchema)
          if (giftErrors) {
            errors = [...errors, ...giftErrors]
            break
          }
        }
      return errors
    }

    // handle organ
    const organImgFlip = ref(false)
    const newOrganTitle = ref('')
    const organBtnTitle = ref('شریک تجاری')
    const organBtnImg = ref(null)
    const selectedOrganToEdit = ref(null)
    const organAction = ref('new')
    // Initail cropper
    const {
      cropperModal,
      resizedImg,
      imgRef,
      croppedImgBase64,
      croppedImgFile,
      resetCropper,
      handleStartCropper,
      getBothOutputs
    } = imgCropper()

    const organSchema = schemaMaker({
      logo: image('لوگوی سازمان'),
      title: reqString('شریک تجاری')
    })

    const submitOrganForm = () => {
      if (organAction.value === 'new') addNewOrgan()
      else if (organAction.value === 'edit') submitEditOrgan(formItems['organ'])
    }

    const addNewOrgan = async () => {
      if (btnLoading.value) return
      const errors = validateForms(
        { title: newOrganTitle.value, logo: croppedImgBase64.value },
        organSchema
      )

      if (errors) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }
      // api call
      const formData = new FormData()
      formData.append('name', newOrganTitle.value)
      formData.append('image', croppedImgFile.value)

      try {
        btnLoading.value = true
        await addOrgan(formData)
        await store.dispatch('gift/fetchOrgans')
        store.dispatch('addToast', {
          type: 'success',
          message: 'سازمان/شرکت شریک تجاری با موفقیت اضافه گردید. '
        })
        croppedImgBase64.value = ''
        newOrganTitle.value = ''
        modals['newOrgan'] = false
        modals['organ'] = true
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'status',
            status: ex.response.status
          })
        }
      } finally {
        btnLoading.value = false
      }
    }

    const organSchemaEdit = schemaMaker({
      title: reqString('شریک تجاری')
    })
    const handleEditOrgan = (organ) => {
      modals['organ'] = false
      modals['newOrgan'] = true

      selectedOrganToEdit.value = organ
      formItems['organ'] = organ['id']
      newOrganTitle.value = organ['name']
      croppedImgBase64.value = organ['image']
      organAction.value = 'edit'
    }
    const submitEditOrgan = async (id) => {
      if (btnLoading.value) return
      const errors = validateForms(
        { title: newOrganTitle.value },
        organSchemaEdit
      )
      if (errors) {
        for (let error of errors) {
          const message = {
            type: 'error',
            message: error
          }
          store.dispatch('addToast', message)
        }
        return
      }
      // api call
      const formData = new FormData()
      formData.append('name', newOrganTitle.value)
      if (selectedOrganToEdit.value['image'] !== croppedImgBase64.value) {
        formData.append('image', croppedImgFile.value)
      }

      try {
        btnLoading.value = true
        await editOrgan(id, formData)
        await store.dispatch('gift/fetchOrgans')
        btnLoading.value = false
        store.dispatch('addToast', {
          type: 'success',
          message: 'سازمان/شرکت شریک تجاری با موفقیت اصلاح گردید. '
        })
        croppedImgBase64.value = ''
        newOrganTitle.value = ''
        modals['newOrgan'] = false
        modals['organ'] = true
      } catch (ex) {
        if (ex.response) {
          store.dispatch('addToast', {
            type: 'status',
            status: ex.response.status
          })
        }
        btnLoading.value = false
      }
    }
    const handleDeleteOrgan = async (id) => {
      try {
        await deleteOrgan(id)
        store.dispatch('gift/fetchOrgans')
        store.dispatch('addToast', {
          type: 'success',
          message: 'سازمان/شرکت شریک تجاری با موفقیت حذف گردید. '
        })
      } catch (ex) {
        if (ex && ex.response) {
          const message = {
            type: 'status',
            status: ex.response.status
          }
          store.dispatch('addToast', message)
        }
      }
    }

    // final submition
    const formSchema = schemaMaker({
      expireDay: modalNumber('تاریخ اعتبار', 1),
      organ: modalNumber('شریک تجاری', 1)
    })

    const { generateExpireTime } = dateConvertor()

    const submitForm = async () => {
      if (btnLoading.value || isDone.value) return
      let errors = []
      const giftErrors = validateGifts()
      if (giftErrors) errors = [...errors, ...giftErrors]
      const { expireDay, organ } = formItems
      const formErrors = validateForms({ expireDay, organ }, formSchema)
      if (formErrors) errors = [...errors, ...formErrors]

      if (errors.length !== 0) {
        // # add a alert box to show this message

        for (let key of errors) {
          const message = { type: 'error', message: key }
          store.dispatch('addToast', message)
        }
        return
      }
      formItems['expire'] = generateExpireTime(formItems['expireDay'])
      btnLoading.value = true
      try {
        await addGift(giftSet, formItems)
        isDone.value = true
        btnLoading.value = false
        store.dispatch('addToast', {
          type: 'success',
          message: 'بن های خرید با موفقیت ایجاد گردیدند.'
        })

        store.dispatch('fetchHomeData')
        setTimeout(() => router.push({ name: 'GiftHome' }), 2000)
      } catch (ex) {
        switch (ex.response.status) {
          case 402:
            store.dispatch('profile/handleCreditModal', {
              status: 'open',
              type: 'credit'
            })
            break
          default:
            store.dispatch('addToast', {
              type: 'error',
              message: 'درخواست شما موفقیت‌آمیز نبود.'
            })
        }
      } finally {
        btnLoading.value = false
      }
    }

    return {
      btnLoading,
      days,
      formItems,
      items,
      modals,
      modalLoading,
      organs,
      giftSet,
      giftSum,
      giftFinalPrice,
      giftTablePrice,
      selectedSet,
      addSet,
      submitForm,
      btnDay,
      // new organ
      organImgFlip,
      newOrganTitle,
      addNewOrgan,
      organBtnTitle,
      organBtnImg,
      handleDeleteOrgan,
      handleEditOrgan,
      submitEditOrgan,
      organAction,
      submitOrganForm,
      // cropper
      cropperModal,
      handleStartCropper,
      resizedImg,
      imgRef,
      resetCropper,
      croppedImgBase64,
      getBothOutputs
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-new-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to bottom, #eee 0, #fff 40px);

  .bon-count-input {
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 0;
    .gift-btn {
      font-size: 14px;
      color: #333;
      height: 50px;
      min-width: 110px;
      padding: 0 12px;
      line-height: 50px;
      display: table-cell;
      text-align: center;
      border-radius: 15px;
      font-size: 15px;
      background-color: #fff;
      box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &.active {
        color: #c69d4e;
        font-size: 22px;
        span {
          font-size: 18px;
          color: #777;
        }
      }
    }
  }
}

.circleBtn {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  margin: 15px 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.circleBtn__img {
  fill: #777;
  width: 40px;
  height: 40px;
}

.organ-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 0 20px;
  width: 100%;
  background-color: #eee;
}
.organ-modal--linear {
  background: linear-gradient(to bottom, #fff 0, #eee 100%);
}
.organ {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.organ__row {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  height: 56px;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 10px;
  background: linear-gradient(
    to right,
    #bbb 38px,
    #ddd 38px,
    #ddd 76px,
    #fff 76px
  );
}
.organ__img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  pointer-events: none;
}
.organ__title {
  color: #111;
  font-size: 16px;
  width: 100%;
  text-align: right;
  pointer-events: none;
  margin-right: 6px;
}
.organ__hr {
  width: 92%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
  &:last-child {
    display: none;
  }
}
.organ__vr {
  height: 100%;
  width: 1px;
  // background-color: #3f3f3f;
  justify-self: center;
  margin: 0 6px;
}
.organ__btn-delete {
  // background-color: #ddd;
  width: 30px;
  height: 30px;
  line-height: 20px;
  font-size: 26px;
  padding: 4px 0 0;
  margin: 0;
  // border-radius: 100%;
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  // border-radius: 4px;
}
.organ__btn-edit {
  // background-color: #ddd;
  // border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
  svg {
    transform: rotate(30deg);
    fill: #777;
    width: 26px;
    height: 26px;
  }
}

.organ-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 0 20px;
}

.bta-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  .bta-title {
    width: 100%;
    text-align: right;
    font-size: 15px;
    color: #b4b4b4;
    margin-bottom: 5px;
  }
}

// change in common css
.credit-buttons {
  margin: 30px 0;
}
.button--modal {
  background-color: #fff;
  width: 92%;
  // margin-bottom: 15px;
}
.button--modal .button__icon {
  fill: #777;
}
.button__text.text--organ {
  font-size: 16px;
}
.button__text.active--detail {
  font-size: 18px;
  color: #c69d4e;
}

.button--form {
  margin: 0;
}
.button--black {
  margin: 0;
}

.button--img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.buttons--filter {
  margin: 0;
  width: 90%;
  justify-content: space-between;
}

.dropzone-container .file-wrapper {
  border-radius: 15px;
}
</style>
